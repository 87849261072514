import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';

export default el => {

    const $el = $(el);
    const $menuToggleBtn = $el.find('[data-menutoggle]');
    const $menu = $($menuToggleBtn.get(0).nextElementSibling);
    const $menuWrapper = $menuToggleBtn.parent();

    let isMenuOpen = false;

    const openMenu = () => {
        if (isMenuOpen) {
            return;
        }
        isMenuOpen = true;
        $menuToggleBtn.attr('aria-expanded', 'true');
        $menu.removeClass('hidden');
        gsap.killTweensOf($menu.get(0));
        gsap.timeline()
            .fromTo($menu.get(0), { opacity: 0 }, { opacity: 1, duration: 0.3 });
        Viewport.lockTabbing($menuWrapper.get(0), $menuToggleBtn.get(0));
    };

    const closeMenu = (tween = true) => {
        if (!isMenuOpen) {
            return;
        }
        isMenuOpen = false;
        $menuToggleBtn.attr('aria-expanded', 'false');
        gsap.killTweensOf($menu.get(0));
        const afterClose = () => {
            $menu.addClass('hidden');
            gsap.set($menu.get(0), { clearProps: 'all' });
        };
        if (tween) {
            gsap.timeline({
                onComplete: afterClose
            })
                .to($menu.get(0), { opacity: 0, duration: 0.3 });
        } else {
            afterClose();
        }
        Viewport.releaseTabbing($menuToggleBtn.get(0));
    };

    const toggleMenu = () => {
        if (isMenuOpen) {
            closeMenu();
        } else {
            openMenu();
        }
    };

    const onMenuToggleClick = e => {
        toggleMenu();
    };

    const onBreakpoint = () => {
        if ($menuToggleBtn.get(0).firstElementChild.offsetParent === null) {
            closeMenu(false);
        }
    };

    const init = () => {
        $menuToggleBtn.on('click', onMenuToggleClick);
        Viewport.on('breakpoint', onBreakpoint);
    };

    const destroy = () => {
        $menuToggleBtn.off('click', onMenuToggleClick);
        Viewport.off('breakpoint', onBreakpoint);
    };

    return {
        init,
        destroy
    };

};
