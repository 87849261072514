import gsap from 'gsap';
import throttle from 'lodash/throttle';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';

const DEFAULT_STAGGER = 250;

const tweens = new WeakMap();
const timeouts = new WeakMap();

let nodes = [];
let currentScrollTop = -1;
let direction = 'down';
let observer;
let intersecting = [];

const getTween = el => {

    let tween = tweens.get(el);

    if (tween) {
        return tween;
    }

    el.setAttribute('data-revealed', true);

    const {
        reveal: type,
        revealDelay: delay = 0
    } = el.dataset;

    let {
        revealDuration: duration,
        revealEase: ease
    } = el.dataset;

    if (duration !== undefined) {
        duration = parseFloat(duration);
    }

    tween = gsap.timeline({
        paused: true,
        delay
    });

    if (type === 'y') {

        const { revealY: y = '10vmin' } = el.dataset;

        if (duration === undefined) {
            duration = 1.5;
        }

        if (ease === undefined) {
            ease = 'Expo.easeOut';
        }

        tween
            .fromTo(el, {
                y
            }, {
                y: 0,
                ease,
                duration
            }, 0)
            .fromTo(el, {
                opacity: 0.001
            }, {
                opacity: 1,
                duration: duration * 0.5,
                ease: 'Sine.easeInOut'
            }, 0);

    } else {

        // Default is fade
        if (duration === undefined) {
            duration = 0.75;
        }

        if (ease === undefined) {
            ease = 'Sine.easeInOut';
        }

        tween
            .fromTo(el, { opacity: 0.001 }, {
                opacity: 1,
                duration,
                ease
            });
    }

    tween.set(el, { clearProps: 'transform,opacity' });

    tweens.set(el, tween);

    return tween;

};

const onScroll = () => {
    const { scrollTop } = Viewport;
    direction = scrollTop >= currentScrollTop ? 'down' : 'up';
    currentScrollTop = scrollTop;
};

const trackNodes = () => {
    $('[data-reveal]:not([data-revealed])')
        .each(node => {
            if (!node.offsetParent) {
                return;
            }
            observer.observe(node);
            nodes.push(node);
        });
};

const scrollHandler = throttle(onScroll, 10);

const onObserve = entries => {

    entries.forEach(entry => {

        const {
            target,
            isIntersecting
        } = entry;

        // Get tween
        const tween = getTween(target);
        let timeout = timeouts.get(target);
        if (timeout) {
            clearTimeout(timeout);
            timeouts.delete(target);
        }

        if (isIntersecting) {
            intersecting.push(target);
            // Easiest way I could think of to sort the array of intersecting elements according to their chronological position in the DOM (which is a good idea)
            intersecting = nodes.filter(node => intersecting.indexOf(node) > -1);
        } else {
            intersecting = intersecting.filter(node => node !== target);
        }

        const { top } = target.getBoundingClientRect();

        if (!isIntersecting && direction === 'up' && top >= Viewport.height) {
            // Reset the effect
            tween.pause(0, false);
            return;
        }

        // Calculate base stagger
        let stagger = target.dataset.revealStagger;
        if (stagger === undefined) {
            stagger = DEFAULT_STAGGER;
        }
        stagger = parseInt(stagger, 10);

        if (!isIntersecting && top < 0) {

            tween.pause(tween.duration(), false);

        } else if (isIntersecting && !tween.progress()) {

            stagger *= Math.max(0, intersecting.filter(node => getTween(node) && getTween(node)
                .progress() <= 0.05)
                .indexOf(target));

            if (!stagger) {
                tween.play();
                return;
            }

            timeout = setTimeout(() => {
                clearTimeout(timeout);
                timeouts.delete(target);
                tween.play();
            }, stagger);
        }

    });

};

const createObserver = () => {

    observer = new IntersectionObserver(onObserve, {
        threshold: [0, 0.5, 1],
        rootMargin: '0% 0% 10% 0%'
    });

};

const init = () => {

    onScroll();

    createObserver();

    trackNodes();

    window.addEventListener('scroll', scrollHandler);

};

const update = () => {

    if (!observer) {
        return;
    }

    // Unobserve nodes that are no longer in the DOM
    nodes = nodes.reduce((carry, node) => {
        if (node.closest('html')) {
            return carry.concat(node);
        }
        observer.unobserve(node);
        const tween = tweens.get(node);
        if (tween) {
            tweens.delete(node);
            tween.kill();
        }
        const timeout = timeouts.get(node);
        if (timeout) {
            timeouts.delete(node);
            clearTimeout(timeout);
        }
        return carry;
    }, []);

    trackNodes();
};

export default ({
    init,
    update,
    tweens
});
