import gsap from 'gsap';
import $ from '../core/Dom';

const BUTTON_SELECTOR = '[data-btn]';

export default (el, { name, duration }) => {

    const $el = $(el);

    const onAccept = () => {
        gsap.to(el, {
            yPercent: 100,
            duration: 0.5,
            onComplete() {
                el.hidden = true;
            }
        });
    };

    const onClick = () => {
        window.vrsg.setCookie(name, 'accepts', parseInt(duration, 10));
        onAccept();
    };

    const init = () => {
        $el.on('click', BUTTON_SELECTOR, onClick);
    };

    const destroy = () => {
        $el.off('click');
    };

    return {
        init,
        destroy
    };
};
