import Flickity from 'flickity';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';

export default el => {

    const slider = el.firstElementChild;
    const $slides = $(el).find('[data-slide]');

    let { width: viewW, height: viewH } = Viewport;

    let flkty;

    const destroyFlickity = () => {
        if (flkty) {
            flkty.destroy();
            flkty = null;
        }
        $slides.off('focusin');
    };

    const createFlickity = () => {
        destroyFlickity();
        flkty = new Flickity(slider, {
            contain: true,
            dragThreshold: 15,
            cellAlign: 'left',
            prevNextButtons: false,
            pageDots: false,
            freeScroll: false,
            freeScrollFriction: 0.045,
            setGallerySize: false,
            resize: false,
            accessibility: true,
            on: {
                dragStart() {
                    document.ontouchmove = e => e.preventDefault();
                    $slides.find('a').css({ pointerEvents: 'none' });
                },
                dragEnd() {
                    document.ontouchmove = () => true;
                    $slides.find('a').css({ pointerEvents: '' });
                }
            }
        });
        $slides.on('focusin', e => {
            flkty.select(Number(e.delegateTarget.dataset.slide));
        });
    };

    const onResize = () => {
        const { width, height } = Viewport;
        if (width === viewW && Math.abs(height - viewH) < 150) {
            return;
        }
        viewW = width;
        viewH = height;
        if (flkty) {
            flkty.resize();
        }
    };

    const init = () => {
        createFlickity();
        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
        destroyFlickity();
    };

    return {
        init,
        destroy
    };

};
