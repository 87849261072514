import gsap from 'gsap';
import Viewport from './core/Viewport';
import Components from './core/Components';
import lazySizes from './lib/lazysizes';
import ViewportHeight from './lib/ViewportHeight';
import Reveal from './lib/Reveal';

import { isLivePreview } from './lib/helpers';

gsap.defaults({ overwrite: 'auto' });

const init = () => {
    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    ViewportHeight.init();
    lazySizes.init();

    if (!isLivePreview()) {
        Reveal.init();
    }
};

require('doc-ready')(init);
